import http from "@/api/http";

const PREFIX="/device";
const IS_MOCK = process.env.VUE_APP_IS_MOCK == 'true';

async function getControlData(param){
    let url = `${PREFIX}/optdata`
    if (!IS_MOCK) {
        return http.get(url, param);
    } else {
        const mockReponse = { 
            "mwdDevice": {
                "insertId": null,
                "createDateTime": "2019-11-28T21:19:20Z",
                "updateDateTime": "2019-11-28T21:19:20Z",
                "mwdMac": param.mwdMac,
                "mwdType": param.mwdType,
                "enable": "Y",
                "pm10": 12,
                "pm25": 10,
                "co2": 200,
                "voc": 100,
                "temp": 10,
                "humm": 60
            }
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        });
    }
}

async function updateControlData(param){
    let url = `${PREFIX}/optdata`
    if (!IS_MOCK) {
        return http.post(url, param);
    } else {
        const mockReponse = { 
            "mwdDevice": {
                "insertId": null,
                "createDateTime": "2019-11-28T21:19:20Z",
                "updateDateTime": "2019-11-28T21:19:20Z",
                "mwdMac": param.mwdMac,
                "mwdType": param.mwdType,
                "enable": "Y",
                "pm10": 12,
                "pm25": 10,
                "co2": 200,
                "voc": 100,
                "temp": 10,
                "humm": 60
            }
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        });
    }
}

export default {
    getControlData,
    updateControlData
};