const firebaseConfig = {
  apiKey: "AIzaSyCVnG0iKgapzlB5cYf7wNVMvtC07v-ufOM",
  authDomain: "misewatchweb.firebaseapp.com",
  projectId: "misewatchweb",
  storageBucket: "misewatchweb.appspot.com",
  messagingSenderId: "796946009796",
  appId: "1:796946009796:web:524475e59ca71ecbabc391",
  measurementId: "G-Z3B1HQVEVP"
};

export default firebaseConfig