<template>
  <div id="wrapper2">
    <router-view></router-view>
    <div class="loading-layer" :class="[{'off': !loading}]">
      <div class="loading-wrap flex_cc">
        <!-- <img src="@/assets/img/loading.gif" /> -->
        <div class="loading_box">
          <img class="img_load img01" src="@/assets/img/ico_loading.png" />
          <img class="img_logo img02" src="@/assets/img/main_logo_md_skin.png" v-if="isSkin" />
          <img class="img_logo img02" src="@/assets/img/main_logo_md.png" v-else />
        </div>
      </div>         
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters("common", {
      loading: "axiosStatus"
    }),
    isSkin() {
      let url = this.$common.getUrl().url;
      return url == this.$common.URL_PORTAL_SKINNSKIN || url == this.$common.URL_GANGSEO_SKINNSKIN;
    }
  }
}
</script>


