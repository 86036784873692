import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'; //ik
import auth from "./modules/auth";
import school from "./modules/school";
import common from "./modules/common";
import others from "./modules/others";
Vue.use(Vuex)

export default new Vuex.Store({
  modules: { 
    auth,
    school,
    common,
    others
  },
  plugins: [
    //주목! : 여기에 쓴 모듈만 저장됩니다.
    createPersistedState({
      paths: ['others'],
    })
  ]
});