  <template>

  <!-- aside -->
    <aside id="aside" class="app-aside">
      
      <div class="aside-wrap2" style="background:#f00;">
        <div class="navi-wrap">
          <!-- nav -->
          <nav ui-nav class="navi clearfix">
            
            <ul class="nav" id="ui-nav">
              <li class="menu01" :class="{'active': isActive('/main')}" v-if="schoolListLength !== 0">
                <a href class="auto">
                  <i class="glyphicon glyphicon-stats icon text-primary-dker"></i>
                  <span class="font-bold">실시간현황</span>
                </a>
                <ul class="nav nav-sub dk">
                   <router-link to="/main/total" tag="li">
                      <a>
                        <span>전체현황</span>
                      </a>
                    </router-link> 
                    <router-link to="/main/individual" tag="li">
                      <a>
                        <span>개별현황</span>
                      </a>
                    </router-link>
                    <!-- ikkim
                    <router-link to="/main/" tag="li">
                      <a>
                        <span>자동전환</span>
                      </a>
                    </router-link>
                    -->
                </ul>
              </li>
              
              <li class="menu02" :class="{'active': isActive('/settings')}" v-if="authInfo.authority < 2 && schoolListLength !== 0">
                <a href class="auto">
                  <i class="glyphicon glyphicon-stats icon text-primary-dker"></i>
                  <span class="font-bold">기기제어</span>
                </a>
                <ul class="nav nav-sub dk">
                  <router-link to="/settings/simple" tag="li">
                    <a>
                      <span>선택제어</span>
                    </a>
                  </router-link>
                  <router-link to="/settings/reserve" tag="li">
                    <a>
                      <span>예약</span>
                    </a>
                  </router-link>
                  <router-link to="/settings/history" tag="li">
                    <a>
                      <span>동작이력</span>
                    </a>
                  </router-link>
                  <router-link to="/settings/time" tag="li">
                    <a>
                      <span>공통시간 설정</span>
                    </a>
                  </router-link>
                  <router-link to="/settings/holiday" tag="li">
                    <a>
                      <span>공휴일 설정</span>
                    </a>
                  </router-link>
                </ul>
              </li>
              <!--
              <li class="menu03">
                <a href class="auto">
                  <span class="pull-right text-muted">
                    
                  </span>
                  <i class="glyphicon glyphicon-stats icon text-primary-dker"></i>
                  <span class="font-bold">실시간현황</span>
                </a>
                <ul class="nav nav-sub dk">
                  <li>
                   <router-link to="/" tag="a" class="">
                      <span>전체현황</span>
                    </router-link>
                  </li>
                </ul>
              </li>
              -->
              
              <li class="menu03" :class="{'active': isActive('/stat')}" v-if="authInfo.authority < 2 && schoolListLength !== 0">
                <a href class="auto">
                  <i class="glyphicon glyphicon-stats icon text-primary-dker"></i>
                  <span class="font-bold">전문 통계</span>
                </a>
                <ul class="nav nav-sub dk">
                   <router-link to="/stat/date" tag="li">
                    <a>
                      <span>측정 기록</span>
                    </a>
                  </router-link>
                  <router-link to="/stat/useHistory" tag="li">
                    <a>
                      <span>기기 가동 기록</span>
                    </a>
                  </router-link>
                </ul>
              </li>
              <!-- ikkim FCM move to below
              <li class="menu04"  :class="{'active': isActive('/ctrl_inq_account')}" v-if="authInfo.authority < 2 && schoolListLength !== 0">
                <a href class="auto">
                  <i class="glyphicon glyphicon-stats icon text-primary-dker"></i>
                  <span class="font-bold">조회계정 관리</span>
                </a>
                <ul class="nav nav-sub dk">
                  <router-link to="/ctrl_inq_account" tag="li">
                    <a>
                      <span>조회계정 관리</span>
                    </a>
                  </router-link>
                </ul>
              </li>-->

              <li class="menu05" :class="{'active': isActive('/installation')}" v-if="authInfo.authority < 2 && schoolListLength !== 0">
                <a href class="auto">
                  <i class="glyphicon glyphicon-stats icon text-primary-dker"></i>
                  <span class="font-bold">설치관리</span>
                </a>
                <ul class="nav nav-sub dk">
                  <router-link to="/installation/installation_place" tag="li">
                    <a>
                      <span>설치 장소</span>
                    </a>
                  </router-link>
                   <router-link to="/installation/installation_num" tag="li">
                      <a>
                        <span>설치번호 발급</span>
                      </a>
                    </router-link>
                </ul>
              </li>

              <li class="menu06" :class="{'active': isActive('/notice')}" v-if="authInfo.authority < 2 && schoolListLength !== 0">
                <a href class="auto">
                  <i class="glyphicon glyphicon-stats icon text-primary-dker"></i>
                  <span class="font-bold">공지사항</span>
                </a>
                <ul class="nav nav-sub dk">
                  <router-link to="/notice" tag="li">
                    <a>
                      <span>공지사항</span>
                    </a>
                  </router-link>
                </ul>
              </li>


              <!-- ikkim FCM move hear -->
              <li class="menu04"  :class="{'active': isActive('/ctrl_inq_account')}" v-if="schoolListLength !== 0">
                <a href class="auto">
                  <i class="glyphicon glyphicon-stats icon text-primary-dker"></i>
                  <span class="font-bold">계정 관리</span>
                </a>
                <ul class="nav nav-sub dk">
                  <router-link to="/ctrl_inq_account/ctrl_inq_account_main" tag="li" v-if="authInfo.authority < 2">
                    <a>
                      <span>조회계정 관리</span>
                    </a>
                  </router-link>
                  <router-link to="/ctrl_inq_account/notification" tag="li">
                    <a>
                      <span>부가기능 설정</span>
                    </a>
                  </router-link>

                  <!-- no need in desktop
                  <router-link to="/login" tag="li">
                    <a>
                      <span @click="onLogoutRequested">로그아웃</span>
                    </a>
                  </router-link>
                  -->
                </ul>
              </li>


              <!-- ikkim  -->
              <!-- admin, misewatch가 아니면서 authority == 0을 가진 user에게 관리자메뉴 접근은 하지 못한다.
              차후 관리자 메뉴 중 일부를 접근할 수 있게 준비는 해 놓았다. -->
              <li class="menu07" :class="{'active': isActive('/admin'), 'admin': schoolListLength !== 0}" v-if="authInfo.authority == 0 || authInfo.authority == 10"> 

                <a href class="auto">
                  <i class="glyphicon glyphicon-stats icon text-primary-dker"></i>
                  <span class="font-bold">관리자메뉴</span>
                </a>
                <ul class="nav nav-sub dk">
                  <router-link to="/admin/school" tag="li" v-if="authInfo.authority == 0">
                    <a>
                      <span>사이트</span>
                    </a>
                  </router-link>
                  <router-link to="/admin/manager" tag="li">
                    <a>
                      <span>담당자</span>
                    </a>
                  </router-link>
                  <router-link to="/admin/setting-company" tag="li" v-if="authInfo.authority == 0">
                    <a>
                      <span>설치회사</span>
                    </a>
                  </router-link>
                  <router-link to="/admin/misse-terminal" tag="li" v-if="authInfo.authority == 0">
                    <a>
                      <span>미세와치 단말</span>
                    </a>
                  </router-link>
                  <router-link to="/admin/cleaner" tag="li" v-if="authInfo.authority == 0">
                    <a>
                      <span>공기청정기</span>
                    </a>
                  </router-link>
                  <router-link to="/admin/circulator" tag="li" v-if="authInfo.authority == 0">
                    <a>
                      <span>공기순환기</span>
                    </a>
                  </router-link>
                  <router-link to="/admin/notice-manage" tag="li">
                    <a>
                      <span>공지관리</span>
                    </a>
                  </router-link>
                  <router-link to="/admin/firmware" tag="li" v-if="authInfo.authority == 0">
                    <a>
                      <span>펌웨어 업로드</span>
                    </a>
                  </router-link>
                </ul>
              </li>   


              

                      
            </ul>
          </nav>
          <!-- nav -->

        </div>

      <!--210930-->
      <div class="privatepolicy">
        <div><router-link to="/private_policy" tag="a">개인정보처리방침</router-link></div>
      </div>


      </div>


    </aside>
    <!-- / aside -->
</template>
<script>
const DEFAULT_LIMIT = 5;
export default {
  data() {
    return {
      currentUuid : null
    }
  },
  computed: {
    authInfo(){
      return this.$store.getters['auth/authenticationUserInfo'];

      /*
      let _auth = this.$store.getters['auth/authenticationUserInfo'];

      console.log(JSON.stringify(_auth));

      return _auth;
      */
    },
    schoolListLength(){
      return this.$store.getters['school/schoolList'].length;
    }
  },
  methods: {
    isActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0 // current path starts with this path string
      });
    },
  },
}
</script>