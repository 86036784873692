+function ($) {

  $(function(){

      // nav
      $(document).on('click', '[ui-nav] a', function (e) {
        var $this = $(e.target), $active;
        $this.is('a') || ($this = $this.closest('a'));

        $active = $this.parent().siblings( ".active" );
        $active && $active.toggleClass('active').find('> ul:visible').slideUp(200);

        if(!$this.hasClass("auto")){
          var parent = $this.closest('ul').parent();
          var active = $("#ui-nav").children('.active')
          for(var i = 0; i < active.length; i++){
            var element = $(active[i]);
            if(!parent.is(element)){
              element.toggleClass('active').find('> ul:visible').slideUp(200);
            }
          }
        }
        
        ($this.parent().hasClass('active') && $this.next().slideUp(200)) || $this.next().slideDown(200);
        $this.parent().toggleClass('active');
        
        $this.next().is('ul') && e.preventDefault();
      });

  });
}(jQuery);