import http from "@/api/http";
const PREFIX="/notice";
const IS_MOCK = process.env.VUE_APP_IS_MOCK == 'true';

async function getNoticeListForAdmin(param) {
  let url = `${PREFIX}/admin`;
  if(!IS_MOCK) {
    return http.post(url, param);
  } else {
    const mockResponse = {totalCnt: 1, notice: [{noticeIdx: "3", title: "dd  ddfdfdf", contents: "ddddddddd", createDateTime: "2020-01-21 04:32:14.0", recvSchool: [{noticeIdx: "3", uuid: "c8a10db7-bd49-4ad2-bda9-e8d2623b99b8", name: "용인초등학교"},{noticeIdx: "3", uuid: "a7c13a52-9e50-434c-b0e9-fa3b009163d6", name: "돌마초등학교"}]}]};
    return new Promise((resolve) => {
      setTimeout(()=> {
        resolve(mockResponse);
      }, 100);
    })
  }
}

async function getNoticeList(param) {
  let url = `${PREFIX}/_search`;
  if(!IS_MOCK) {
    return http.post(url, param);
  } else {
    const mockResponse = {totalCnt: 1, notice: [{noticeIdx: "3", title: "dd ddddd", contents: "dddddddd", createDateTime: "2020-01-21 04:32:14.0", recvSchool: [{noticeIdx: "3", uuid: "c8a10db7-bd49-4ad2-bda9-e8d2623b99b8", name: "용인초등학교"},{noticeIdx: "3", uuid: "a7c13a52-9e50-434c-b0e9-fa3b009163d6", name: "돌마초등학교"}]}]};
    return new Promise((resolve) => {
      setTimeout(()=> {
        resolve(mockResponse);
      }, 100);
    })
  }
}
async function createNotice(param) {
  if(!IS_MOCK) {
    return http.post(PREFIX, param);
  } else {
    const mockResponse = {};
    return new Promise((resolve) => {
      setTimeout(()=> {
        resolve(mockResponse);
      }, 100);
    })
  }
}
function getNotice(noticeIdx) {
  if(!IS_MOCK) {
    return http.get(PREFIX, {noticeIdx});
  } else {
    const mockResponse = {noticeIdx: "3", title: "dd 테스트 타이틀 입니다.", contents: "dddddddd", createDateTime: "2020-01-21 04:32:14.0", recvSchool: [{noticeIdx: "3", uuid: "c8a10db7-bd49-4ad2-bda9-e8d2623b99b8", name: "용인초등학교"},{noticeIdx: "3", uuid: "a7c13a52-9e50-434c-b0e9-fa3b009163d6", name: "돌마초등학교"}]};
    return new Promise((resolve) => {
      setTimeout(()=> {
        resolve(mockResponse);
      }, 100);
    })
  }
}
function getNoticeNoti(noticeIdx) {
  if(!IS_MOCK) {
    return http.get(`${PREFIX}/noti`, {noticeIdx});
  } else {
    const mockResponse = {noticeIdx: "3", title: "dd 테스트 타이틀 입니다.", contents: "dddddddd", createDateTime: "2020-01-21 04:32:14.0", recvSchool: [{noticeIdx: "3", uuid: "c8a10db7-bd49-4ad2-bda9-e8d2623b99b8", name: "용인초등학교"},{noticeIdx: "3", uuid: "a7c13a52-9e50-434c-b0e9-fa3b009163d6", name: "돌마초등학교"}]};
    return new Promise((resolve) => {
      setTimeout(()=> {
        resolve(mockResponse);
      }, 100);
    })
  }
}

//220124
function getNoticeByTitle(title, uuid) {
  if(!IS_MOCK) {
    return http.get(`${PREFIX}/title`, {title:title, uuid:uuid});
  } else {
    //const mockResponse = {noticeIdx: "3", title: "dd", contents: "환경에어케어서비스", createDateTime: "2020-01-21 04:32:14.0", recvSchool: [{noticeIdx: "3", uuid: "c8a10db7-bd49-4ad2-bda9-e8d2623b99b8", name: "용인초등학교"},{noticeIdx: "3", uuid: "a7c13a52-9e50-434c-b0e9-fa3b009163d6", name: "돌마초등학교"}]};
    const mockResponse = {};
    return new Promise((resolve) => {
      setTimeout(()=> {
        resolve(mockResponse);
      }, 100);
    })
  }
}

function getNoticeForAdmin(noticeIdx){
  if(!IS_MOCK) {
    return http.get(`${PREFIX}/admin/view`, {noticeIdx});
  } else {
    const mockResponse = {noticeIdx: "3", title: "dd", contents: "dddd", createDateTime: "2020-01-21 04:32:14.0", recvSchool: [{noticeIdx: "3", uuid: "c8a10db7-bd49-4ad2-bda9-e8d2623b99b8", name: "용인초등학교"},{noticeIdx: "3", uuid: "a7c13a52-9e50-434c-b0e9-fa3b009163d6", name: "돌마초등학교"}]};
    return new Promise((resolve) => {
      setTimeout(()=> {
        resolve(mockResponse);
      }, 100);
    })
  }
}


function getPrevNextUrlForAdmin(noticeIdx, type, schoolUuid = null) {
  let url = `${PREFIX}/prevNextForAdmin`;
  if(!IS_MOCK) {
    return http.get(url, {noticeIdx: noticeIdx, type: type, schoolUuid: schoolUuid});
  } else {
    const mockResponse = {noticeIdx: null, title: null, contents: null, createDateTime: null, recvSchool: null};
    return new Promise((resolve) => {
      setTimeout(()=> {
        resolve(mockResponse);
      }, 100);
    })
  }
}

async function getPrevNextNoticeForAdmin(noticeIdx, schoolUuid = null) {
  let httpList = [];
  httpList.push(getPrevNextUrlForAdmin(noticeIdx, 'prev', schoolUuid));
  httpList.push(getPrevNextUrlForAdmin(noticeIdx, 'next', schoolUuid));
  if(!IS_MOCK) {
    return http.all(httpList);
  } else {
    const mockResponse = [{noticeIdx: "4", title: "두번째글", contents: "글내요없다", createDateTime: "2020-01-21T05:23:05Z", recvSchool: null},
      {noticeIdx: null, title: null, contents: null, createDateTime: null, recvSchool: null}];
    return new Promise((resolve) => {
      setTimeout(()=> {
        resolve(mockResponse);
      }, 100);
    })
  }
}

function getPrevNextUrl(noticeIdx, type, schoolUuid = null) {
  let url = `${PREFIX}/prevNext`;
  if(!IS_MOCK) {
    return http.get(url, {noticeIdx: noticeIdx, type: type, schoolUuid: schoolUuid});
  } else {
    const mockResponse = {noticeIdx: null, title: null, contents: null, createDateTime: null, recvSchool: null};
    return new Promise((resolve) => {
      setTimeout(()=> {
        resolve(mockResponse);
      }, 100);
    })
  }
}

async function getPrevNextNotice(noticeIdx, schoolUuid = null) {
  let httpList = [];
  httpList.push(getPrevNextUrl(noticeIdx, 'prev', schoolUuid));
  httpList.push(getPrevNextUrl(noticeIdx, 'next', schoolUuid));
  if(!IS_MOCK) {
    return http.all(httpList);
  } else {
    const mockResponse = [{noticeIdx: "4", title: "두번째글", contents: "글내요없다", createDateTime: "2020-01-21T05:23:05Z", recvSchool: null},
      {noticeIdx: null, title: null, contents: null, createDateTime: null, recvSchool: null}];
    return new Promise((resolve) => {
      setTimeout(()=> {
        resolve(mockResponse);
      }, 100);
    })
  }
}

async function updateNotice(notice) {
  if(!IS_MOCK) {
    return http.put(PREFIX, notice);
  } else {
    const mockResponse = {};
    return new Promise((resolve) => {
      setTimeout(()=> {
        resolve(mockResponse);
      }, 100);
    })
  }
}

async function deleteNotice(noticeIdx) {
  let url = `${PREFIX}/${noticeIdx}`
  if(!IS_MOCK) {
    return http.delete(url);
  } else {
    const mockResponse = {};
    return new Promise((resolve) => {
      setTimeout(()=> {
        resolve(mockResponse);
      }, 100);
    })
  }
}

export default {
  getNoticeListForAdmin,
  getNoticeList,
  createNotice,
  getNoticeForAdmin,
  getNotice,
  getNoticeNoti,
  getNoticeByTitle,
  getPrevNextNoticeForAdmin,
  getPrevNextNotice,
  updateNotice,
  deleteNotice
}
