import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/main/Main.vue'
import store from "@/store";

//220305
import crypto from "@/lib/crypto";

Vue.use(VueRouter)

const IS_MOCK = process.env.VUE_APP_IS_MOCK == 'true';
const IS_SAAS = process.env.VUE_APP_IS_SAAS == 'true'

/**
 * 최고 관리자 권한 체크함수
 * 관리자메뉴 접근 전 권한 체크
 */
const superAdminAccess = () => (to, from, next) => {
  // mock data일 경우 
  if(IS_MOCK) {
    return next();
  }
  if(store.getters['auth/authenticationUserInfo'].authority == 0 || store.getters['auth/authenticationUserInfo'].authority == 10) {
    return next();
  }else { // 권한이 없는 경우 현재 페이지에 
    return next(false);
  }
}
/**
 * 학교 리스트 없을 경우 확인 로직
 */
const schoolListCheck = () => (to, from, next) => {
  // mock data일 경우 
  if(IS_MOCK) {
    return next();
  }

  //============= 220208  
  // if(IS_SAAS && store.getters['auth/authenticationUserInfo'].passForceChange >= 7/*60*/) {
  //   console.log("to.name2="+to.name);
  //   //let curPath = router.currentRoute.fullPath;
  //   //console.log("@@@@@@ curPath==="+curPath+" to.name="+to.name+ " to.path="+to.path);
  //   //if(to.name != 'info_change_password') {
  //     next("/info_change/info_change_password");
  //   //}
  //   return;
  // }
  //============= 220208

  //if(to.path.match("/info_change_basic") || to.path.match("/info_change_password")) { //계정관리에서 정보변경, 비밀번호 수정은 무조건 PASS
  if(to.path.match("/ctrl_inq_info_change_basic") || to.path.match("/ctrl_inq_info_change_password")) { //계정관리에서 정보변경, 비밀번호 수정은 무조건 PASS
      return next();
  }else if(!store.getters['auth/getSchoolUUID']) { // 학교가 존재하지 않으면
    return next("/admin");
  }/*else if(store.getters['auth/authenticationUserInfo'].authority == 2 && (!to.path.match("/main") && !to.path.match("/notification"))){
    //조회계정은 main(전체현황, 개별현황만 볼 수 있다.)
    //조회계정은 main과 계정관리-부가기능설정 까지 볼수 있다로 변경. 220119
    return next(false);
  }*/ else { // 학교가 존재하면
    return next();
  }
  
}
const routes = [
  {
    path: '/',
    redirect:  'main' ,
    //redirect:  'empty' ,
    component: Main,
    children:
      [
        {
          path: 'main',
          name :'main',
          beforeEnter: schoolListCheck(),
          redirect:  { name: 'total' },
          component: () => import(/* webpackChunkName: "about" */ '@/views/dashboard/Dashboard.vue'),
          children:[
            {
              path: 'total/:param?',
              name:'total',
              component: () => import(/* webpackChunkName: "about" */ '@/views/dashboard/Total.vue')
            },
            {
              path: 'individual',
              redirect: '/main/individual/1',
              //component: () => import(/* webpackChunkName: "about" */ '@/views/dashboard/Individual.vue')
            },
            {
              path: 'individual/:gradeName',
              props: true,
              name: 'individual',
              component: () => import(/* webpackChunkName: "about" */ '@/views/dashboard/Individual.vue')
            }
          ]
        },

        // {
        //   path: 'map',
        //   name: 'map',
        //   beforeEnter: schoolListCheck(),
        //   redirect: { name: 'site_map' },
        //   component: () => import(/* webpackChunkName: "about" */ '@/views/dashboard/empty_view.vue'),
        //   children: [
        //     {
        //       path: 'site_map',
        //       name: 'site_map',
        //       component: () => import(/* webpackChunkName: "about" */ '@/views/dashboard/Map.vue')
        //     }
        //   ]
        // },

        {
          path: 'settings',
          name: 'settings',
          beforeEnter: schoolListCheck(),
          redirect: { name: 'time' },
          component: () => import(/* webpackChunkName: "about" */ '@/views/settings/Setting.vue'),
          children:
            [
              {
                path: 'simple',
                name: 'simple',
                component: () => import(/* webpackChunkName: "about" */ '@/views/settings/Simple.vue'),
              },
              {
                path: 'reserve',
                name: 'reserve',
                component: () => import(/* webpackChunkName: "about" */ '@/views/settings/reserve.vue'),
              },
              {
                path: 'history',
                name: 'history',
                component: () => import(/* webpackChunkName: "about" */ '@/views/settings/history.vue'),
              },
              {
                path: 'historytoday',
                name: 'historytoday',
                component: () => import(/* webpackChunkName: "about" */ '@/views/settings/historytoday.vue'),
              },
              {
                path: 'time',
                name: 'time',
                component: () => import(/* webpackChunkName: "about" */ '@/views/settings/time.vue'),
              },
              {
                path: 'holiday',
                name: 'holiday',
                component: () => import(/* webpackChunkName: "about" */ '@/views/settings/Holiday.vue')
              },
              {
                path: 'etc',
                name: 'etc',
                props: route => ({
                  gatewayMac: route.query.gatewayMac
                }),
                component: () => import(/* webpackChunkName: "about" */ '@/views/settings/etc.vue')
              },
              {
                path: 'clock',
                name: 'clock',
                component: () => import(/* webpackChunkName: "about" */ '@/views/settings/clock.vue')
              },
            ]
        },
        {
          path: 'admin',
          name: 'admin',
          redirect: { name: 'school-list' },
          component: () => import(/* webpackChunkName: "about" */ '@/views/admin/Admin.vue'),
          children: [
            {
              path: 'cleaner',
              name: 'cleaner',
              redirect: { name: 'ApcCleaner' },
              component: () => import(/* webpackChunkName: "about" */ '@/views/admin/empty_view.vue'),
              children: [
                {
                  path: 'cleaner-list',
                  name: 'ApcCleaner',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/cleaner/CleanerList.vue'),
                },
                {
                  path: 'cleaner-register',
                  name: 'ApcCleanerRegister',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/cleaner/Register.vue'),
                  props: route => ({
                    apcModelNo: route.query.apcModelNo
                  })
                }
              ]
            },
            {
              path: 'circulator',
              name: 'circulator',
              redirect: { name: 'ApcCirculator' },
              component: () => import(/* webpackChunkName: "about" */ '@/views/admin/empty_view.vue'),
              children: [
                {
                  path: 'circulator-list',
                  name: 'ApcCirculator',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/circulator/CirculatorList.vue'),
                },
                {
                  path: 'circulator-register',
                  name: 'ApcCirculatorRegister',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/circulator/Register.vue'),
                  props: route => ({
                    apcModelNo: route.query.apcModelNo
                  })
                }
              ]
            },
            {
              path: 'school',
              name: 'school',
              redirect: { name: 'school-list' },
              component: () => import(/* webpackChunkName: "about" */ '@/views/admin/empty_view.vue'),
              children: [
                {
                  path: 'school-list',
                  name: 'school-list',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/school/SchoolList.vue')
                },
                {
                  path: 'school-regist/:schoolNo',
                  // path: 'school-regist',
                  name: 'school-regist',
                  props: true,
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/school/SchoolRegist.vue'),
                }
              ]
            },
            //add 220611
            {
              path: 'sitemanager',
              name: 'sitemanager',
              redirect: { name: 'sitemanager-list' },
              component: () => import(/* webpackChunkName: "about" */ '@/views/admin/empty_view.vue'),
              children: [
                {
                  path: 'sitemanager-list',
                  name: 'sitemanager-list',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/manager/SiteManagerList.vue'),
                },
                {
                  path: 'sitemanager-regist/:userNo',
                  name: 'sitemanager-regist',
                  props: true,
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/manager/SiteManagerRegist.vue'),
                }
              ]
            },
            {
              path: 'manager',
              name: 'manager',
              redirect: { name: 'manager-list' },
              component: () => import(/* webpackChunkName: "about" */ '@/views/admin/empty_view.vue'),
              children: [
                {
                  path: 'manager-list',
                  name: 'manager-list',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/manager/ManagerList.vue'),
                },
                {
                  path: 'manager-regist/:userNo',
                  name: 'manager-regist',
                  props: true,
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/manager/ManagerRegist.vue'),
                }
              ]
            },
            //220613 조회계정관리 (Admin 조회시)
            {
              path: 'viewer',
              name: 'viewer',
              redirect: { name: 'viewer-list' },
              component: () => import(/* webpackChunkName: "about" */ '@/views/admin/empty_view.vue'),
              children: [
                {
                  path: 'viewer-list',
                  name: 'viewer-list',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/manager/ViewerList.vue'),
                },
                {
                  path: 'viewer-regist/:userNo',
                  name: 'viewer-regist',
                  props: true,
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/manager/ViewerRegist.vue'),
                }
              ]
            },
            {
              path: 'setting-company',
              name: 'setting-company',
              redirect: { name: 'setting-company-list' },
              component: () => import(/* webpackChunkName: "about" */ '@/views/admin/empty_view.vue'),
              children: [
                {
                  path: 'setting-company-list',
                  name: 'setting-company-list',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/setting-company/SettingCompanyList.vue')
                },
                {
                  path: 'setting-company-regist/:companyNo',
                  name: 'setting-company-regist',
                  props: true,
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/setting-company/SettingCompanyRegist.vue'),
                }
              ]
            },
            {
              path: 'misse-terminal',
              name: 'misse-terminal', 
              redirect: { name: 'gateway' },
              component: () => import(/* webpackChunkName: "about" */ '@/views/admin/misse-terminal/Terminal.vue'),
              children: [
                {
                  path: 'terminal',
                  name: 'terminal',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/misse-terminal/Terminal.vue'),
                },
                {
                  path: 'gateway',
                  name: 'gateway',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/misse-terminal/Gateway.vue')
                },
                {
                  path: 'indoor',
                  name: 'indoor',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/misse-terminal/Indoor.vue'),
                },
                {
                  path: 'station',
                  name: 'station',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/misse-terminal/Station.vue'),
                }
              ]
            },
            {
              path: 'notice-manage',
              name: 'notice-manage',
              redirect: { name: 'notice-list' },
              component: () => import(/* webpackChunkName: "about" */ '@/views/admin/empty_view.vue'),
              children: [
                {
                  path: 'notice-list',
                  name: 'notice-list',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/notice-manage/NoticeList.vue')
                },
                {
                  path: 'notice-view',
                  name: 'notice-view',
                  props: route => ({
                    noticeIdx: route.query.noticeIdx
                  }),
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/notice-manage/NoticeView.vue'),
                },
                {
                  path: 'notice-write',
                  name: 'notice-write',
                  props: route => ({
                    noticeIdx: route.query.noticeIdx
                  }),
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/notice-manage/NoticeWrite.vue'),
                }
              ]
            },
            {
              path: 'firmware',
              name: 'firmware',
              redirect: { name: 'Apcfirmware' },
              component: () => import(/* webpackChunkName: "about" */ '@/views/admin/firmware/Firmware.vue'),
              children: [
                {
                  path: 'firmware',
                  name: 'Apcfirmware',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/firmware/Firmware.vue')
                },
              ]
            },
            {
              path: 'banner',
              name: 'banner',
              redirect: { name: 'bannerUpload' },
              component: () => import(/* webpackChunkName: "about" */ '@/views/admin/banner/banner.vue'),
              children: [
                {
                  path: 'banner',
                  name: 'bannerUpload',
                  beforeEnter: superAdminAccess(),
                  component: () => import(/* webpackChunkName: "about" */ '@/views/admin/banner/banner.vue')
                },
              ]
            },
          ]
            
        },

        {
          path: 'stat',
          name: 'stat',
          beforeEnter: schoolListCheck(),
          redirect: { name: 'date' },
          component: () => import(/* webpackChunkName: "about" */ '@/views/stat/stat.vue'),
          children:
            [ 
              {
                path: 'date',
                name: 'date',
                redirect: { name: 'daily' },
                component: () => import(/* webpackChunkName: "about" */ '@/views/stat/date.vue'),
                children:
                [
                  {
                    path: 'daily',
                    name: 'daily',
                    component: () => import(/* webpackChunkName: "about" */ '@/views/stat/daily.vue'),
                  },
                  {
                    path: 'weekly',
                    name: 'weekly',
                    component: () => import(/* webpackChunkName: "about" */ '@/views/stat/weekly.vue'),
                  },
                  {
                    path: 'monthly',
                    name: 'monthly',
                    component: () => import(/* webpackChunkName: "about" */ '@/views/stat/monthly.vue'),
                  },
                  {
                    path: 'yearly',
                    name: 'yearly',
                    component: () => import(/* webpackChunkName: "about" */ '@/views/stat/yearly.vue'),
                  },
                  {
                    path: 'date_setting',
                    name: 'date_setting',
                    component: () => import(/* webpackChunkName: "about" */ '@/views/stat/date_setting.vue'),
                  },
                ]
              },
              {
                path: 'usehistory',
                name: 'usehistory',
                component: () => import(/* webpackChunkName: "about" */ '@/views/stat/UseHistory.vue'),
              },
              {
                path: 'usehistorynew',
                name: 'usehistorynew',
                component: () => import(/* webpackChunkName: "about" */ '@/views/stat/UseHistoryNew.vue'),
              }
             
            ]
        },
        {
          path: 'notice',
          name: 'notice',
          beforeEnter: schoolListCheck(),
          redirect: { name: 'notice_main' },
          component: () => import(/* webpackChunkName: "about" */ '@/views/notice/notice.vue'),
          children:
            [
              {
                path: 'notice_main',
                name: 'notice_main',
                component: () => import(/* webpackChunkName: "about" */ '@/views/notice/main.vue'),
              },
              {
                path: 'notice_view',
                name: 'notice_view',
                props: route => ({
                  noticeIdx: route.query.noticeIdx
                }),
                component: () => import(/* webpackChunkName: "about" */ '@/views/notice/notice_view.vue'),
              }
            ]
        },
        {
          path: 'sinage',
          name: 'sinage',
          beforeEnter: schoolListCheck(),
          redirect: { name: 'sinage_main' },
          component: () => import(/* webpackChunkName: "about" */ '@/views/sinage/sign.vue'),
          children:
            [
              {
                path: 'sinage_main',
                name: 'sinage_main',
                component: () => import(/* webpackChunkName: "about" */ '@/views/sinage/managesign.vue'),
              },
              // {
              //   path: 'notice_view',
              //   name: 'notice_view',
              //   props: route => ({
              //     noticeIdx: route.query.noticeIdx
              //   }),
              //   component: () => import(/* webpackChunkName: "about" */ '@/views/notice/notice_view.vue'),
              // }
            ]
        },
        {
          path: 'ctrl_inq_account',
          name: 'ctrl_inq_account',
          beforeEnter: schoolListCheck(),
          redirect: { name: 'ctrl_inq_info_change_basic' },
          component: () => import(/* webpackChunkName: "about" */ '@/views/ctrl_inq_account/ctrl_inq_account.vue'),
          children:
            [
              {
                path: 'ctrl_inq_info_change_basic',
                name: 'ctrl_inq_info_change_basic',
                component: () => import(/* webpackChunkName: "about" */ '@/views/ctrl_inq_account/info_change_basic.vue'),
              },
              {
                path: 'ctrl_inq_info_change_password',
                name: 'ctrl_inq_info_change_password',
                component: () => import(/* webpackChunkName: "about" */ '@/views/ctrl_inq_account/info_change_password.vue'),
              },

              {
                path: 'ctrl_inq_account_setting',
                name: 'ctrl_inq_account_setting',
                redirect: { name: 'ctrl_inq_account_main' },
                component: () => import(/* webpackChunkName: "about" */ '@/views/admin/empty_view.vue'),
                children:
                [
                  {
                    path: 'ctrl_inq_account_main',
                    name: 'ctrl_inq_account_main',
                    component: () => import(/* webpackChunkName: "about" */ '@/views/ctrl_inq_account/main.vue')
                  },
                  {
                    path: 'new_account/:userId',
                    name: 'new_account',
                    props: true,
                    component: () => import(/* webpackChunkName: "about" */ '@/views/ctrl_inq_account/new_account.vue')
                  }
                ]
              },
              {
                path: 'info',
                name: 'info',
                component: () => import(/* webpackChunkName: "about" */ '@/views/ctrl_inq_account/info.vue'),
              },
              
              //ikkim
              {
                path: 'notification',
                name: 'notification',
                //props: true,
                component: () => import(/* webpackChunkName: "about" */ '@/views/ctrl_inq_account/notification.vue')
              },
              {
                path: 'schoolsetting',
                name: 'schoolsetting',
                //props: true,
                component: () => import(/* webpackChunkName: "about" */ '@/views/ctrl_inq_account/SchoolSetting.vue')
              },
              {
                path: 'installation_firmware',
                name: 'installation_firmware',
                //component: () => import(/* webpackChunkName: "about" */ '@/views/installation/install_firmware.vue')
                component: () => import(/* webpackChunkName: "about" */ '@/views/admin/firmware/Firmware.vue')
              }
              //,
              //{
              //  path: 'info_change_basic',
              //  name: 'info_change_basic',
              //  component: () => import(/* webpackChunkName: "about" */ '@/views/ctrl_inq_account/info_change_basic.vue'),
              //},
              //{
              //  path: 'info_change_password',
              //  name: 'info_change_password',
              //  component: () => import(/* webpackChunkName: "about" */ '@/views/ctrl_inq_account/info_change_password.vue'),
              //}
            ]
        },

        
        //탄소모니터
        // {
        //   path: 'cmonitor',
        //   name: 'cmonitor',
        //   beforeEnter: schoolListCheck(),
        //   redirect: { name: 'cmonitor_main' },
        //   component: () => import(/* webpackChunkName: "about" */ '@/views/cmonitor/cmonitor_menu.vue'),
        //   children:
        //     [
        //       {
        //         path: 'cmonitor_main',
        //         name: 'cmonitor_main',
        //         component: () => import(/* webpackChunkName: "about" */ '@/views/cmonitor/cmonitor_main.vue')
        //       },
        //       {
        //         path: 'cmonitor_sub',
        //         name: 'cmonitor_sub',
        //         //props: true,
        //         component: () => import(/* webpackChunkName: "about" */ '@/views/cmonitor/cmonitor_sub.vue')
        //       },
        //     ]
        // },
        
        
        {
          path: 'installation',
          name: 'installation',
          beforeEnter: schoolListCheck(),
          redirect: { name: 'installation_place' },
          component: () => import(/* webpackChunkName: "about" */ '@/views/installation/installation.vue'),
          children:
            [
              {
                path: 'installation_place',
                name: 'installation_place',
                component: () => import(/* webpackChunkName: "about" */ '@/views/installation/install_place.vue')
              },
              {
                path: 'installation_num',
                name: 'installation_num',
                component: () => import(/* webpackChunkName: "about" */ '@/views/installation/install_number.vue')
              },
              {
                path: 'installation_firmware',
                name: 'installation_firmware',
                component: () => import(/* webpackChunkName: "about" */ '@/views/installation/install_firmware.vue')
              }
            ]
        },
        // {
        //   path: 'info_change',
        //   name: 'info_change',
        //   redirect: { name: 'info_change_password' },
        //   component: () => import(/* webpackChunkName: "about" */ '@/views/info_change/info_change.vue'),
        //   children:
        //     [
        //       {
        //         path: 'info_change_basic',
        //         name: 'info_change_basic',
        //         component: () => import(/* webpackChunkName: "about" */ '@/views/info_change/info_change_basic.vue'),
        //       },
        //       {
        //         path: 'info_change_password',
        //         name: 'info_change_password',
        //         component: () => import(/* webpackChunkName: "about" */ '@/views/info_change/info_change_password.vue'),
        //       }
        //       //ikkim
        //       //{
        //       //  path: 'notification',
        //       //  name: 'notification',
        //       //  //props: true,
        //       //  component: () => import(/* webpackChunkName: "about" */ '@/views/info_change/notification.vue')
        //       //}
        //     ]
        // },
        {
          path: '/private_policy',
          name: 'private_policy',
          component: () => import(/* webpackChunkName: "about" */ '@/views/private_policy/private_policy.vue')
        },

        //co monitor menu
        {
          path: 'cmonitor',
          name: 'cmonitor',
          beforeEnter: schoolListCheck(),
          redirect: { name: 'co_dashboard' },
          component: () => import(/* webpackChunkName: "about" */ '@/views/cmonitor/cmonitor.vue'),
          children:
            [ 
              {
                path: 'co_dashboard',
                name: 'co_dashboard',
                component: () => import(/* webpackChunkName: "about" */ '@/views/cmonitor/co_dashboard.vue'),
              },
              {
                path: 'impl_list',
                name: 'impl_list',
                component: () => import(/* webpackChunkName: "about" */ '@/views/cmonitor/impl_list.vue'),
              },
              {
                path: 'ranking',
                name: 'ranking',
                component: () => import(/* webpackChunkName: "about" */ '@/views/cmonitor/ranking.vue'),
              },
              {
                path: 'co_regist',
                name: 'co_regist',
                component: () => import(/* webpackChunkName: "about" */ '@/views/cmonitor/co_regist.vue'),
              },
              {
                path: 'co_yearly',
                name: 'co_yearly',
                component: () => import(/* webpackChunkName: "about" */ '@/views/cmonitor/co_yearly.vue'),
              },
              {
                path: 'co_setting',
                name: 'co_setting',
                component: () => import(/* webpackChunkName: "about" */ '@/views/cmonitor/co_setting.vue'),
              },
              {
                path: 'setting_impl_list',
                name: 'setting_impl_list',
                component: () => import(/* webpackChunkName: "about" */ '@/views/cmonitor/setting_impl_list.vue'),
              },
              {
                path: 'co_user',
                name: 'co_user',
                component: () => import(/* webpackChunkName: "about" */ '@/views/cmonitor/co_user.vue'),
              },
              {
                path: 'co_etc',
                name: 'co_etc',
                component: () => import(/* webpackChunkName: "about" */ '@/views/cmonitor/co_etc.vue'),
              }
            ]
        },
      ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/login/Login.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/main/About.vue')
  },
  //220305
  {
    //path: '/reset_password',
    path: '/reset_password/:param?',
    name: 'reset_password',
    component: () => import(/* webpackChunkName: "about" */ '@/views/main/ResetPassword.vue'),
  },
  //240215: 알림을 표시하기 위해
  {
    path: '/notice_noti',
    name: 'notice_noti',
    component: () => import(/* webpackChunkName: "about" */ '@/views/notice/notice_view_noti.vue'),
  },
  //240315: 게이트웨이 알림을 표시하기 위해
  {
    path: '/push',
    name: 'push',
    component: () => import(/* webpackChunkName: "about" */ '@/views/notice/notice_view_noti_gateway.vue'),
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if(IS_MOCK) {

    console.log("to.name2="+to.name+" to.path="+to.path+" from.name="+from.name);

    if(to.name=='reset_password' && to.path != null) {

      let param = to.path.split("/");
      if(param.length == 3) {
        let pass = crypto.SHA256("dlshdigital0!0@~");
        //console.log("pass="+pass);
        //console.log("len="+param.length+" "+param[2]);

        if(pass === param[2]) {
          next();
          return;
        }
      } 

      next('/login');
      return;
    }

    next();    
  }
  else {
    //211104
    /*
    if(Vue.prototype.$modal.vm != null) {
      Vue.prototype.$modal.vm.close();
      return;
    }
    */

    //console.log("to.name="+to.name+ "  a = "+store.getters['others/isShowChangePassword']);
    //console.log("to.name="+to.name+" to.path="+to.path+" from.name="+from.name+" passForceChange="+store.getters['auth/authenticationUserInfo'].passForceChange);
    //console.log("passForceChange="+store.getters['auth/authenticationUserInfo'].passForceChange);

    //console.log("passForceChange="+store.getters['auth/authenticationUserInfo'].passForceChange);
    //console.log("to.name="+to.name+ "  isAuthenticated = "+store.getters['auth/isAuthenticated']);
    
    if(to.name == 'login') {
      next();
    } else if(store.getters['auth/isAuthenticated']) {
      
      //============= 220208
      // if(IS_SAAS && store.getters['auth/authenticationUserInfo'].passForceChange >= 7/*60*/ && 
      //   (from.name=='info_change_password' || !to.path.includes("info_change_password"))) {
        
      //   if(!to.path.includes("info_change_password")) {
      //     //back 또는 url에 직접 입력하여 admin으로 진입하면 school_check를 하지 않기에 별도 처리를 해야 한다.
      //     console.log("goto password change menu again.");
      //     next("/info_change/info_change_password");
      //   }
      //   return;
      // }
      if(IS_SAAS && store.getters['auth/authenticationUserInfo'].passForceChange >= 90) {
        if(from.name=='ctrl_inq_info_change_password' /*'info_change_password'*/) {
          //nothing to do.
          return;
        } else if(!to.path.includes("ctrl_inq_info_change_password" /*"info_change_password"*/)) {
          console.log("goto password change menu again.");
          //next("/info_change/info_change_password");
          next("/ctrl_inq_account/ctrl_inq_info_change_password");
          return;
        }
      }
      //============= 220208
  
      next();
    }else {
      //220305-----------------------------------------
      if(to.name=='reset_password' && to.path != null) {

        let param = to.path.split("/");
        if(param.length == 3) {
          let pass = "176bf421d8c63b5c26a586d661d66ac2aa37d5aa5bd586fcafb8a8c47653c715";
          //console.log("pass="+pass);
          //console.log("len="+param.length+" "+param[2]);

          if(pass === param[2]) {
            next();
            return;
          }
        } 
      } else if(to.name=='notice_noti' || to.name=='push') {
        next();
        return;
      }
      //220305-----------------------------------------

      next('/login');
    }
  }
})

export default router
