//211001
const WEB_PATH = process.env.VUE_APP_WEB_DIR;

import http from "@/api/http";

function login( userId, userPwd, userOtp ) {
    let url = '/login'
    let path = WEB_PATH;
    //console.log("userOtp:"+userOtp);
    return http.post(url, {
        userId,
        userPwd,
        userOtp, //211101
        path
    });
}

function logout(  ) {
    let url = '/logout'
    return http.post(url);
}

export default {
    login,
    logout
};