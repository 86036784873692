<template>
  <div id="app" class="app app-header-fixed">
    <!--Header-->
    <Header/>
    <!--Aside-->
    
    <!--<Aside/>-->
    
    <!-- content -->
   
    <div id="content" class="app-content" role="main">
   <perfect-scrollbar>    
      <div class="app-content-body">
          <router-view />
      </div>
    </perfect-scrollbar>  
    </div>
    
    <!-- /content -->

    <Footer/>

  </div>
</template>


<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Aside from '@/components/Aside.vue'


export default {
  name: 'App',
  components: {
    Header,
    //Aside
  },
  async created(){
    this.getSchoolInfo();
  },
  mounted(){
  },
  methods: {

    /** 
     * 학교정보를 가져온다.
     * 
     * @method getSchoolInfo 
     * @param {String} uuid - 학교 고규 UUID, Admin의 경우에 UUID가 존재하지 않음
     */
    getSchoolInfo(){
      this.$store.dispatch('school/getSchoolInfo')
    }
  }
}
</script>

