const state = {
  axiosStatus: null,
  dashboardStatusCount: 0
}

const getters = {
  axiosStatus: (state) => state.axiosStatus? true:false,
  dashboardStatusCount : (state) => state.dashboardStatusCount,
}

const mutations = {
  updateAxiosStatus(state, status) {
    state.axiosStatus = status;
  },
  updateDashboardStatusCount(state, status) {
    state.dashboardStatusCount = status;
  }
}

const actions = {
  updateAxiosStatus({commit}, status) {
    commit("updateAxiosStatus", status);
  },
  updateDashboardStatusCount({commit}, status) {
    commit("updateDashboardStatusCount", status);
  }
}
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};