import moment from 'moment';

const cmonitor = {
    install(Vue) {

        Vue.prototype.$cmonitor = {};

        Vue.prototype.$cmonitor.costatus = function (data, schoolName, slideText) {
            let ret = new Object();

            let frDt, toDt, startDt, endDt;
            if(data.mwdDateTime){
				frDt = moment(data.mwdDateTime);
			}else{
				frDt = moment(data.classDateTime);
			}

            toDt = moment(new Date()).subtract(1, 'day');

            startDt = moment(new Date()).month(0).date(1);
            endDt = moment(new Date()).month(11).date(31);

            let diff = moment(frDt).diff(moment(startDt));
            if(diff < 0) {
                frDt = startDt;
            }

            //console.log("data="+JSON.stringify(data));
            
            // console.log("diff="+diff);
            // console.log("frDt="+frDt+" toDt="+toDt);
            //console.log("frDtStr="+frDt.format("YYYY년 MM월 DD일")+" toDtStr="+toDt.format("YYYY년 MM월 DD일"));

            // console.log("startDt="+startDt+" endDt="+endDt);
            //console.log("startDtStr="+startDt.format("YYYY년 MM월 DD일")+" endDtStr="+endDt.format("YYYY년 MM월 DD일"));

            let daysEdu = 300; //수업일수를 설정한다.
            let dayAvgC = 7.2; //하루 평균 7.2시간 공기청정기 사용으로 가정한다.
            let reduceGoalPercent = 50; //50% reduce;
            let saveKgPerHour = 0.011994101; //공기청정기 1대를 1시간 OFF했을때 줄일수 있는 이산화탄소배출량.
            let treeCo2KgPerHour = 0.000913242; //30년산 나무 한그루가 한시간동안 흡수하는 이산환탄소량
            let treeCo2KgPerYear = 8.6;

            let totalDevice = data.classLen;
            let totalApAcClassLen = data.apAcClassLen;

            //console.log("totalDevice="+totalDevice+" totalApAcClassLen="+totalApAcClassLen);


            ret.frDt = frDt.format("YYYY년 MM월 DD일");
            ret.toDt = toDt.format("YYYY년 MM월 DD일");

            if(totalApAcClassLen > 0) {

                let yearHours = (daysEdu * dayAvgC) * (reduceGoalPercent / 100); 
                let dayHours = (yearHours / 365);

                let dayTotal = (endDt - toDt) / (1000*3600*24);
                let dayUsed = (toDt - frDt) / (1000*3600*24);
                let dayPassed = (toDt) / (1000*3600*24);

                let targetTotalHours = (yearHours * (dayTotal / 365)) * totalDevice;
                let targetHours = (dayUsed * dayHours) * totalDevice;

                let usedHours = (Number(data.apOpTotalTime0) + Number(data.apOpTotalTime1) + Number(data.acOpTotalTime0 )+ Number(data.acOpTotalTime1)) / 3600;
                let savedHours = targetHours - usedHours;
                let progress = 0;
                
                if(usedHours <= targetHours) {
                    //co2 save
                    progress = (targetHours / targetTotalHours);
                } else {
                    //co2 not save
                    progress = ((targetHours - (usedHours - targetHours)) / targetTotalHours);
                }

                if(progress < 0) progress = 0;
                if(progress > 1) progress = 1;

                // console.log("totalDevice="+totalDevice);
                // console.log("yearHours="+yearHours);
                // console.log("dayHours="+dayHours);

                // console.log("dayTotal="+dayTotal);
                // console.log("dayUsed="+dayUsed);

                // console.log("targetTotalHours="+targetTotalHours);
                // console.log("targetHours="+targetHours);
                // console.log("usedHours="+usedHours);
                // console.log("savedHours="+savedHours);
                // console.log("progress="+progress);

                ret.daysEdu = daysEdu;
                ret.dayAvgC = dayAvgC;
                ret.reduceGoalPercent = reduceGoalPercent;
                ret.totalDevice = totalDevice;
                ret.totalApAcClassLen = totalApAcClassLen;


                ret.yearHours = yearHours;
                ret.dayHours = dayHours;
                ret.dayTotal = dayTotal;
                ret.dayUsed = dayUsed;
                ret.targetTotalHours = targetTotalHours;
                ret.targetHours = targetHours;
                ret.usedHours = usedHours;
                ret.savedHours = savedHours;
                ret.progress = progress;
                ret.saveKg = savedHours * saveKgPerHour;

                let saveKg = ret.saveKg.toFixed(2);    

                if(slideText.length == 0) {
                    slideText = [
                        {text:"$1는 현재까지 탄소배출을 $2kg 줄였습니다.", button:true},
                        {text:"줄어든 탄소배출량은 $1 $2그루를 심는 것과 같습니다.", button:true},
                        {text:"탄소배출을 줄인만큼 이 공간은 나무가 울창한 숲이 됩니다.", button:false},
                        {text:"바로지금, 나부터, 더 늦기전에 2050!!! 탄소중립2050을 실천하여 건강한 지구를 만듭시다.", button:false}
                    ];
                }


                //console.log("slideText="+JSON.stringify(slideText)+" length="+slideText.length);
                if(slideText != null) {
                    //console.log("0: "+slideText[0].text+" 1: "+slideText[1].text);

                    if(slideText.length >= 1) {
                        if(schoolName != undefined) {
                            slideText[0].text = slideText[0].text.replace("$1", schoolName);
                        }
                        slideText[0].text = slideText[0].text.replace("$2", saveKg);
                        //console.log("0: "+slideText[0].text);
                    }

                    if(slideText.length >= 2) {

                        //ret.slideText[0].text = `${schoolName}는 현재까지 탄소배출을 ${saveKg}kg 줄였습니다.`;

                        //30년산 나무 1그루가 1년동안 흡입한 CO2량을 지나간 날짜만큼 계산해서 나무 개수를 구한다.
                        ret.treeOfSaveKg = ret.saveKg / (treeCo2KgPerYear * (dayPassed / 365));

                        let treeOfSaveKg = ret.treeOfSaveKg;
                        let treeType;
                        if(treeOfSaveKg >= 1.0) {
                            treeType = "30년산 소나무"; 
                            treeOfSaveKg = (treeOfSaveKg * 1).toFixed(2);
                        }else if(treeOfSaveKg > 0.1) {
                            treeType = "20년산 소나무";
                            treeOfSaveKg = (treeOfSaveKg * 10).toFixed(2); 
                        }else if(treeOfSaveKg > 0.01) {
                            treeType = "10년산 소나무"; 
                            treeOfSaveKg = (treeOfSaveKg * 100).toFixed(2);
                        }else {
                            treeType = "어린소나무"; 
                            treeOfSaveKg = (treeOfSaveKg * 1000).toFixed(2);
                        }
                        //ret.slideText[1].text = `줄어든 탄소배출량은 ${treeType} ${treeOfSaveKg}그루를 심는것과 같습니다.`;
                        slideText[1].text = slideText[1].text.replace("$1", treeType);
                        slideText[1].text = slideText[1].text.replace("$2", treeOfSaveKg);
                        //console.log("1: "+slideText[1].text);
                    }
                }

                ret.slideText = slideText;

            } else {
                ret.totalDevice = totalDevice;
                ret.totalApAcClassLen = totalApAcClassLen;
                ret.progress = 0;
                ret.slideText = [
                    {text:"탄소중립 에너지환경케어서비스를 사용하면 자율운전으로 탄소배출량을 줄일 수 있습니다.", button:false},
                    {text:"탄소배출을 줄인만큼 이 공간은 나무로 울창한 숲이 됩니다.", button:false},
                    {text:"바로지금, 나부터, 더 늦기전에 2050!!! 탄소중립2050을 실천하여 건강한 지구를 만듭시다.", button:false}
                  ];
            }

            return ret;
        }
    }


}

export default cmonitor;