import {Modal} from 'bootstrap.native'
import 'bootstrap.native/dist/polyfill'

const modal = {
    install(Vue) {
        
        Vue.prototype.$modal = {};

        /**
         * Modal open
         */

        Vue.prototype.$modal.vm = null; //211104
        Vue.prototype.$modal.backclose = false; //211104
        Vue.prototype.$modal.open = function (id, vm) {
          Vue.prototype.$modal.vm = vm; //211104
          //console.info(id);
            if( id != null ){
                let modal = document.getElementById(id);
                let modalInstance = new Modal(modal);
                if( vm != null ){
                    modal.addEventListener('show.bs.modal', function(event){
                        // do something when this event triggers
                        // event.target is the modal referenced in myModal
                        // event.relatedTarget is the button referenced with myModalTriggerButton
                        this.$emit("show");
                      }.bind(vm), false);
                  
                      // shown.bs.modal event
                      modal.addEventListener('shown.bs.modal', function(event){
                        // do something when this event triggers
                        // event.target is the modal referenced in myModal
                        // event.relatedTarget is the button referenced with myModalTriggerButton
                        this.$emit("shown");
                      }.bind(vm), false);
                  
                      // hide.bs.modal event
                      modal.addEventListener('hide.bs.modal', function(event){
                        // do something when this event triggers
                        // event.target is the modal referenced in myModal
                        this.$emit("hide");
                      }.bind(vm), false);
                  
                      // hidden.bs.modal event
                      modal.addEventListener('hidden.bs.modal', function(event){
                        // do something when this event triggers
                        // event.target is the modal referenced in myModal
                        this.$emit("hidden");
                      }.bind(vm), false);
                }
                modalInstance.show();

                //211104 dummy history
                //android에서 history stack이 없을 때 바로 program종료하는 것을 막기위해.
                //popup이 떴을때 back버턴을 누르면 먼저 팝업을 지우고, 한번 더 누르면 back으로 가기 위해,
                history.pushState({state:"modal"}, "", "");

                return modalInstance
            }
            return null;
        }

        Vue.prototype.$modal.close = function (id) {
            Vue.prototype.$modal.vm = null; //211104            
            let modal = document.getElementById(id);
            if( modal != null ){
                let modalInstance = new Modal(modal);
                modalInstance.hide();

                //alert(Vue.prototype.$modal.backclose);
                if(Vue.prototype.$modal.backclose == false) {
                  //history.back();
                }
                Vue.prototype.$modal.backclose = false;
            }
        }

    }
}

export default modal;
