import http from "@/api/http";

const PREFIX = '/co2save'
const IS_MOCK = process.env.VUE_APP_IS_MOCK == 'true'

async function getStdNoExp(id) {
    let url = `${PREFIX}/getstdnoexp`
    if (!IS_MOCK) {
        return http.get(url, {no:id});
    } else {
        const mockReponse = { 
            data : {
                resultCode: 0,
                msgCode: 0,
                msgName: null,
                msgNameList: null,
                data: {
                    regexp: "/^[Y][0-9]{8}$/"
                }
            }
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function createStudent(studentInfo) {
    let url = `${PREFIX}/student`
    if (!IS_MOCK) {
        return http.post(url, studentInfo);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function getStudent(studentInfo) {
    let url = `${PREFIX}/studentreg`
    if (!IS_MOCK) {
        return http.post(url, studentInfo);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function getStudentImpl(studentImpl) {
    let url = `${PREFIX}/studentimpl`
    if (!IS_MOCK) {
        return http.post(url, studentImpl);
    } else {
        const mockReponse = {"data":[ 
            {
                "schoolNo":"Y12345678",
                "studentNo":83,
                "gradeName":"0",
                "className":"0",
                "num":"0",
                "implDate":"2022-09-01",
                "implList": [
                    {"name":"등교교통수단","kgCo2":0,"value":4,"unit":"","inputType":1,mode:0},
                    {"name":"하교교통수단","kgCo2":0,"value":19,"unit":"","inputType":1,mode:0},
                    {"name":"텀블러","kgCo2":0,"value":"1","unit":"번사용","inputType":2,mode:0},
                    {"name":"잔반","kgCo2":0,"value":"12.45","unit":"kg","inputType":0,mode:0},
                ],
                "name":"09-01"
            },
            {
                "schoolNo":"Y12345678",
                "studentNo":83,
                "gradeName":"0",
                "className":"0",
                "num":"0",
                "implDate":"2022-08-31",
                "implList": [{"name":"친환경이동", "leftOver":0,"tumbler":0,"kgCo2":0,"moveItemIn":null,"moveItemOut":null}],
                "name":"08-31"
            },
            {
                "schoolNo":"Y12345678",
                "studentNo":83,
                "gradeName":"0",
                "className":"0",
                "num":"0",
                "implDate":"2022-08-30",
                "implList": [{"name":"친환경이동", "leftOver":0,"tumbler":0,"kgCo2":0,"moveItemIn":null,"moveItemOut":null}],
                "name":"08-30"
            }
        ]
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function setStudentList(studentInfo) {
    let url = `${PREFIX}/studentlist`
    if (!IS_MOCK) {
        return http.post(url, studentInfo);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function getStudentList(param) {
    let url = `${PREFIX}/student`
    if (!IS_MOCK) {
        return http.get(url, param);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function getCoDashboard(param) {
    let url = `${PREFIX}/co2saveimpl_board`
    if (!IS_MOCK) {
        return http.post(url, param);
    } else {
        const mockReponse = {"data":[ 
            {
                "schoolNo":"Y12345678",
                "studentNo":83,
                "gradeName":"0",
                "className":"0",
                "num":"0",
                "implDate":"2022-09-01",
                "implList": [
                    {"name":"등교교통수단","kgCo2":0,"value":4,"unit":"","inputType":1,mode:0},
                    {"name":"하교교통수단","kgCo2":0,"value":19,"unit":"","inputType":1,mode:0},
                    {"name":"텀블러","kgCo2":0,"value":"1","unit":"번사용","inputType":2,mode:0},
                    {"name":"잔반","kgCo2":0,"value":"12.45","unit":"kg","inputType":0,mode:0},
                ],
                "name":"09-01"
            },
            {
                "schoolNo":"Y12345678",
                "studentNo":83,
                "gradeName":"0",
                "className":"0",
                "num":"0",
                "implDate":"2022-08-31",
                "implList": [{"name":"친환경이동", "leftOver":0,"tumbler":0,"kgCo2":0,"moveItemIn":null,"moveItemOut":null}],
                "name":"08-31"
            },
            {
                "schoolNo":"Y12345678",
                "studentNo":83,
                "gradeName":"0",
                "className":"0",
                "num":"0",
                "implDate":"2022-08-30",
                "implList": [{"name":"친환경이동", "leftOver":0,"tumbler":0,"kgCo2":0,"moveItemIn":null,"moveItemOut":null}],
                "name":"08-30"
            }
        ]
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function getStudentImplRank(studentImpl) {
    let url = `${PREFIX}/studentimplrank`
    if (!IS_MOCK) {
        return http.post(url, studentImpl);
    } else {
        const mockReponse = {"data":[ 
            {
                "schoolNo":"Y12345678",
                "studentNo":83,
                "gradeName":"0",
                "className":"0",
                "num":"0",
                "implDate":"2022-09-01",
                "implList": [
                    {"name":"등교교통수단","kgCo2":0,"value":4,"unit":"","inputType":1,mode:0},
                    {"name":"하교교통수단","kgCo2":0,"value":19,"unit":"","inputType":1,mode:0},
                    {"name":"텀블러","kgCo2":0,"value":"1","unit":"번사용","inputType":2,mode:0},
                    {"name":"잔반","kgCo2":0,"value":"12.45","unit":"kg","inputType":0,mode:0},
                ],
                "name":"09-01"
            },
            {
                "schoolNo":"Y12345678",
                "studentNo":83,
                "gradeName":"0",
                "className":"0",
                "num":"0",
                "implDate":"2022-08-31",
                "implList": [{"name":"친환경이동", "leftOver":0,"tumbler":0,"kgCo2":0,"moveItemIn":null,"moveItemOut":null}],
                "name":"08-31"
            },
            {
                "schoolNo":"Y12345678",
                "studentNo":83,
                "gradeName":"0",
                "className":"0",
                "num":"0",
                "implDate":"2022-08-30",
                "implList": [{"name":"친환경이동", "leftOver":0,"tumbler":0,"kgCo2":0,"moveItemIn":null,"moveItemOut":null}],
                "name":"08-30"
            }
        ]
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function setStudentImpl(studentImpl) {
    let url = `${PREFIX}/co2saveimpl`
    if (!IS_MOCK) {
        return http.post(url, studentImpl);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function schoolSelect(selectInfo) {
    let url = `${PREFIX}/schoolselect`
    if (!IS_MOCK) {
        return http.post(url, selectInfo);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function getSaveRate(saveInfo) {
    let url = `${PREFIX}/get_saverate`
    if (!IS_MOCK) {
        return http.post(url, saveInfo);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function setSaveRate(saveInfo) {
    let url = `${PREFIX}/set_saverate`
    if (!IS_MOCK) {
        return http.post(url, saveInfo);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function getStatisticsSave(saveInfo) {
    let url = `${PREFIX}/statistics_save`
    if (!IS_MOCK) {
        return http.post(url, saveInfo);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function getCo2AmountList(param) {
    let url = `${PREFIX}/getco2amount`
    if (!IS_MOCK) {
        return http.post(url, param);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function setCo2AmountList(param) {
    let url = `${PREFIX}/co2amountlist`
    if (!IS_MOCK) {
        return http.post(url, param);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function getCo2SaveItems(param) {
    let url = `${PREFIX}/getco2saveitems`
    if (!IS_MOCK) {
        return http.post(url, param);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function setCo2SaveItems(param) {
    let url = `${PREFIX}/setco2saveitems`
    if (!IS_MOCK) {
        return http.post(url, param);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function getInputTypes() {
    let url = `${PREFIX}/co2saveinputtype`
    if (!IS_MOCK) {
        return http.get(url);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function setInputTypes(param) {
    let url = `${PREFIX}/co2saveinputtype`
    if (!IS_MOCK) {
        return http.post(url, param);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function getImplItemDetail() {
    let url = `${PREFIX}/co2savetype`
    if (!IS_MOCK) {
        return http.get(url);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function setImplItemDetail(param) {
    let url = `${PREFIX}/co2savetypelist`
    if (!IS_MOCK) {
        return http.post(url, param);
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function checkIsCMonitorSchool(no) {
    let url = `${PREFIX}/school`
    if (!IS_MOCK) {
        return http.get(url, {no});
    } else {
        const mockReponse = { 
        }
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(mockReponse);
            }, 100);
        })
    }
}

async function getEarthImageEffect(schoolNo){
    let url = `${PREFIX}/earth`;
    if(!IS_MOCK) {
        return http.get(url, {dir:schoolNo});
    } else {
        const mockResponse = {

            "earth":"{\"earthImage\":[null,null,\"earthImage03.bin\",null,null],\"effectImage\":[null,null,\"effectImage03.bin\",null,null],\"earthIcon\":[\"earthIcon01.bin\",null,\"earthIcon03.bin\",null,null],\"effectSound\":[null,null,\"effectSound03.bin\",null,null],\"earthText\":[\"잔반을 줄여 퍼즐을 벗기면 푸른 지구를 만날 수 있어요!\",\"2\",\"3\",\"4\",\"5\"]}","dir":"704"}
        
        return new Promise((resolve) => {
        setTimeout(() => {
            resolve(mockResponse);
        }, 100);
        })
    }
}

async function setEarthImageEffect(param){
    let url = `${PREFIX}`
    return http.post(url, param, {headers : {
        'Content-type' : 'multipart/form-data'
    }});
}

export default {
    getStdNoExp,
    //gettransportList,
    createStudent,
    getStudent,
    getStudentImpl,
    setStudentList,
    getStudentList,
    getCoDashboard,
    getStudentImplRank,
    setStudentImpl,
    schoolSelect,
    getSaveRate,
    setSaveRate,
    getStatisticsSave,
    getCo2AmountList,
    setCo2AmountList,
    getCo2SaveItems,
    setCo2SaveItems,
    getInputTypes,
    setInputTypes,
    getImplItemDetail,
    setImplItemDetail,
    checkIsCMonitorSchool,
    getEarthImageEffect,
    setEarthImageEffect
};
