import SHA256 from "crypto-js/sha256";
import SHA512 from "crypto-js/sha512";
import SHA3 from "crypto-js/sha3";
import SHA1 from "crypto-js/sha1";

const crypto = {
    SHA1: function (str) {
        return SHA1(str).toString();
    },
    SHA3: function (str) {
        return SHA3(str).toString();
    },
    SHA256: function (str) {
        return SHA256(str).toString();
    },
    SHA512: function (str) {
        return SHA512(str).toString();
    }
};

export default crypto;
