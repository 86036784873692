
//let devMode = process.env.VUE_APP_DEV;

function makeParamData(data){
  let newData = new Object();
  Object.keys(data).forEach((key)=>{    
    if(key === 'method'){
      newData.method = data['method'];
      delete data.method;
    }
  });
  newData.body = data;  
  return newData;
}

function isJsonObject(resp) {
  return typeof resp === "object";
}

/**
 * API 호출결과 json object로부터 resultCode를 추출한다. 만약 resultCode가
 * 존재하지 않거나 유효하지 않는 타입일 경우, Number.MIN_SAFE_INTEGER 값이
 * 리턴된다.
 * 참고:
 *   Number.MIN_SAFE_INTEGER는 -9007199254740991 값을 가지는 상수값.
 *
 * @param {object} resp Api 호출 결과 json object
 */
function getResultCode(resp) {  
  if (
    !isJsonObject(resp) ||
    resp.resultCode === undefined ||
    !Number.isInteger(resp.resultCode)
  ) {
    return Number.MIN_SAFE_INTEGER;
  }
  return resp.resultCode;
}

function convertErrorObj(err) {
  // alert('convertErrorObj' + err );
  /* http 에러일 경우, err 객체에 response객체가 담겨져 온다. */
  if (err.response) {
    return Promise.reject({
      errMsg: err.response.statusText,
      status: err.response.status
    });
  }
  return Promise.reject(err);
}

/**
 * resp.result 엘리먼트가 존재하지 않거나 0이 아니라면, Promise.reject
 * 를 리턴한다.
 *
 * @param {object} API 응답 JSON 객체
 * @returns Promise
 */
function rejectIfNotSuccessful(resp) {
  let resultCode = getResultCode(resp);
  if (resultCode === Number.MIN_SAFE_INTEGER) {
    return Promise.reject({
      errMsg: "알수없는 응답포맷",
      err: resp
    });
  }
  if (resultCode !== 0) {
    return Promise.reject({
      errMsg: "API 호출 실패",
      err: resp
    });
  }
  return resp.data;
  
}

function errorProcess({err}) {
  console.error(err);
  let commonError = [-1, -2, -4011, -5000, -5010, -5020];
  let resultCode = getResultCode(err);
  if(resultCode != 0 && resultCode != Number.MIN_SAFE_INTEGER) {
    if(commonError.indexOf(err.msgCode) > -1) {
      alert(err.msgName);
    }
  }
}
export default {
  getResultCode,    
  convertErrorObj,
  rejectIfNotSuccessful,
  isJsonObject,
  makeParamData,
  errorProcess
};
