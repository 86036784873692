+function ($) {

  $(function(){

      $(document).on('click', '[ui-toggle-class]', function (e) {
        e.preventDefault();
        var $this = $(e.target);
        $this.attr('ui-toggle-class') || ($this = $this.closest('[ui-toggle-class]'));
        
		var classes = $this.attr('ui-toggle-class').split(','),
			targets = ($this.attr('target') && $this.attr('target').split(',')) || Array($this),
			key = 0;
		$.each(classes, function( index ) {
			var target = targets[(targets.length && key)];
			$( target ).toggleClass(classes[index]);
			key ++;
		});
		$this.toggleClass('active');

      });
  });
}(jQuery);
