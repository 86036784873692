<template>
  <div>
    <button class="btn_address" @click="openPopup">주소검색</button>
    <div class="input_address">
      <input type="text" placeholder="우편번호" v-model="inpPostNo" readonly>
      <input type="text" placeholder="주소" v-model="inpAddress" readonly>
      <input type="text" class="address2" placeholder="상세주소" v-model="inpRestAddress" maxlength="20">
    </div>
  </div>
</template>
<script>
import * as typechecker from 'typechecker';

export default {
  name: 'AddressInput',
  props: {
    postNo: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    restAddress: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inpPostNo: '',
      inpAddress: '',
      inpRestAddress: ''
    }
  },
  methods: {
    openPopup() {
      const vm = this;
      window.daum.postcode.load(function() {
        new window.daum.Postcode({
          oncomplete(data) {
            //vm.inpPostNo = data.postcode;
            vm.inpPostNo = data.zonecode; // 새 우편번호로 변경

            if(data.userLanguageType === 'K'){ // 한글주소 검색의 경우
              vm.inpAddress = data.address;
            }else{ // E. 영문 주소 검색의 경우
              vm.inpAddress = data.addressEnglish;
            }
            vm.inpRestAddress = data.buildingName;
          }
        }).open();
      });
    },
    clear() {
      this.inpPostNo = '';
      this.inpAddress = '';
      this.inpRestAddress = '';
    }
  },
  mounted() {
    if (typechecker.isUndefined(window.daum) 
        && !document.getElementById('daum_postcode_v2')) {
      let script = document.createElement('script');
      let DaumUrl = null;
      let localHost = location.protocol;
      if(localHost.indexOf("https:") > -1){
        DaumUrl = "https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
      }
      else{
        DaumUrl = "http://dmaps.daum.net/map_js_init/postcode.v2.js?autoload=false"
      }
      script.setAttribute('src', DaumUrl);
      script.setAttribute('id', 'daum_postcode_v2');
      document.body.appendChild(script);
    }
  },
  watch: {
    postNo(newVal, old) {
      this.inpPostNo = newVal;
    },
    address(newVal, old) {
      this.inpAddress = newVal;
    },
    restAddress(newVal, old) {
      this.inpRestAddress = newVal;
    },
    inpPostNo(newVal) {
      this.$emit('onPostNoChanged', newVal);
    },
    inpAddress(newVal) {
      this.$emit('onAddrChanged', newVal);
    },
    inpRestAddress(newVal) {
      this.$emit('onRestAddrChanged', newVal);
    }
  }
}
</script>