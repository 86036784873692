import http from "@/api/http";

const IS_MOCK = process.env.VUE_APP_IS_MOCK == 'true'

function getGradeSchoolName(data){
  if(data == null) {
      return "";
  }

  if(data.classType == 0) {
      if(data.fullName && !isNaN(Number(data.fullName))==true) {
        return data.gradeName+"학년 "+data.fullName+"반";
      }else{
        return data.fullName;
      }
  }else{
      return data.fullName ? data.fullName : data.className;
  }
}

async function getBannerImage(bannerFileName) {
  let url = "/banner";
  if (!IS_MOCK) {
      return http.get(url, {bannerFileName});
  } else {
      const mockReponse = { 
          
      }

      return new Promise((resolve) => {
          setTimeout(() => {
              resolve(mockReponse);
          }, 100);
      })
  }
}

async function updateBanner(param){
  let url = "/banner/update";
  //return http.put(url, param);
  return http.post(url, param, {headers : {
      'Content-type' : 'multipart/form-data'
    }});
}

export default {
  getGradeSchoolName,
  getBannerImage,
  updateBanner
};