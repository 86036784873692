
//ikkim FCM

import store from "@/store";
import firebase from "firebase/app";
import 'firebase/messaging';
import config from '../../firebaseConfig';
import subscribe from "@/services/subscribe";

let token = {};//ref<string>('');
let messaging = null;


const IS_APP_DEV = process.env.VUE_APP_DEV == 'true'
//const useMesageService = (!navigator.userAgent.toLowerCase().includes("iphone") && !navigator.userAgent.includes("IMG1000A"))
//220121 : gateway portal web, iphone일 경우 meessage service를 지원하지 않는다. (Gateway Webview, iPhone에서는 firebase를 지원하지 않아 앱이 실행안됨)

//alert(navigator.userAgent);
//console.log("reject="+store.getters['others/notiReject']);

function isAllowFCM () {
    //alert("init="+navigator.userAgent);
    //if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|IMG1000A/i.test(navigator.userAgent)) {
    //안드로이드 제품에서는 허용하기로 한다.
    if(/webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|IMG1000A/i.test(navigator.userAgent)) {
        return false;
    } else {
        return true;
    }
}

if(isAllowFCM() /*useMesageService == true*/ && IS_APP_DEV == false) {
    firebase.initializeApp(config);

    //const FCM_SERVER_KEY = 'AAAAuY2vDsQ:APA91bGd-3c7vGaGYQ4BvmwYRnlTcay5Ko_oQuHaSG50fz1DrALiXoxZgGw3lPh2D-P0eC0Naj6VHnLamMI_7faF3ZF6p3Mz2tyZJ545V7kktitNL71w1uSQi9J-lnjiR5QZdO5HPZTG';
    /*const token = {};*/
    /*const*/ messaging = firebase.messaging();
    messaging.getToken().then((currentToken) => {
        if (currentToken) {
            //console.log("token="+currentToken);
            token.value = currentToken;

            //set token
            store.commit('others/setToken', currentToken);
            
            let uuid = store.getters['auth/getSchoolUUID'];
            //console.log("getToken: uuid="+uuid);

            if(uuid != null) {
                subscribe.subscribeTokenToTopic(currentToken, uuid);
            }

            /*
            admin.messaging().subscribeToTopic(currentToken, "ABCDEF")
            .then((response) => {
                // See the MessagingTopicManagementResponse reference documentation
                // for the contents of response.
                console.log('Successfully subscribed to topic:', response);
            })
            .catch((error) => {
                console.log('Error subscribing to topic:', error);
            });
            */
            
        } else {
            // Show permission request.
            console.log('No Instance ID token available. Request permission to generate one.');
        }
    });
}

export default {
    token,
    messaging,
};
